<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power" />
      Edytuj klienta
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm>
            <h4 class="py-3">Adres siedziby </h4>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Firma*"
                  v-model.trim="$v.form.business1.$model"
                  :isValid="checkIfValid('business1')"
                  placeholder="Nazwa firmy"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Nip*"
                  v-model.trim="$v.form.nip1.$model"
                  :isValid="checkIfValid('nip1')"
                  placeholder="Numer NIP"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Regon"
                  v-model.trim="$v.form.regon1.$model"
                  :isValid="checkIfValid('regon1')"
                  placeholder="Numer REGON"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="4">
                <CInput
                  label="Osoba kontaktowa*"
                  v-model.trim="$v.form.contact_person.$model"
                  :isValid="checkIfValid('contact_person')"
                  placeholder="Imię i nazwisko osoby kontaktowej"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Telefon 1"
                  v-model.trim="$v.form.phone1a.$model"
                  :isValid="checkIfValid('phone1a')"
                  placeholder="Numer telefonu 1"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Telefon 2"
                  v-model.trim="$v.form.phone1b.$model"
                  :isValid="checkIfValid('phone1b')"
                  placeholder="Numer telefonu 2"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Telefon 3"
                  v-model.trim="$v.form.phone1c.$model"
                  :isValid="checkIfValid('phone1c')"
                  placeholder="Numer telefonu 2"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  label="Email 1"
                  v-model.trim="$v.form.email1.$model"
                  :isValid="checkIfValid('email1')"
                  placeholder="Adres email 1"
                  invalidFeedback=":invalidFeedback="$i18n.translate('forms.transl30')""
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Email 2"
                  v-model.trim="$v.form.email2.$model"
                  :isValid="checkIfValid('email2')"
                  placeholder="Adres email 2"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Email 3"
                  v-model.trim="$v.form.email3.$model"
                  :isValid="checkIfValid('email3')"
                  placeholder="Adres email 3"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6">
                <CInput
                  label="Kod pocztowy*"
                  v-model.trim="$v.form.postal_code1.$model"
                  :isValid="checkIfValid('postal_code1')"
                  placeholder="Kod pocztowy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Miasto*"
                  v-model.trim="$v.form.city1.$model"
                  :isValid="checkIfValid('city1')"
                  placeholder="Miasto"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  label="Ulica*"
                  v-model.trim="$v.form.street1.$model"
                  :isValid="checkIfValid('street1')"
                  placeholder="Nazwa ulicy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Numer*"
                  v-model.trim="$v.form.number1.$model"
                  :isValid="checkIfValid('number1')"
                  placeholder="Numer budynku"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CSelect id="country_id1"
                         label="Kraj*"
                         v-model.trim="$v.form.country_id1.$model"
                         :options="countries"
                >
                </CSelect>
              </CCol>
              <CCol md="4">
                <CSelect id="provincial_id1"
                         label="Województwo*"
                         v-model.trim="$v.form.provincial_id1.$model"
                         :options="provincials"
                >
                </CSelect>
              </CCol>
            </CRow>



            <h4 class="py-3">Adres korespondencyjny </h4>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Firma"
                  v-model.trim="$v.form.business2.$model"
                  :isValid="checkIfValid('business2')"
                  placeholder="Nazwa firmy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Nip"
                  v-model.trim="$v.form.nip2.$model"
                  :isValid="checkIfValid('nip2')"
                  placeholder="Numer NIP"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Kod pocztowy"
                  v-model.trim="$v.form.postal_code2.$model"
                  :isValid="checkIfValid('postal_code2')"
                  placeholder="Kod pocztowy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Miasto"
                  v-model.trim="$v.form.city2.$model"
                  :isValid="checkIfValid('city2')"
                  placeholder="Miasto"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  label="Ulica"
                  v-model.trim="$v.form.street2.$model"
                  :isValid="checkIfValid('street2')"
                  placeholder="Nazwa ulicy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Numer"
                  v-model.trim="$v.form.number2.$model"
                  :isValid="checkIfValid('number2')"
                  placeholder="Numer budynku"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="4">
                <CSelect id="country_id2"
                         label="Kraj"
                         v-model.trim="$v.form.country_id2.$model"
                         :options="countries"
                >
                </CSelect>
              </CCol>
              <CCol md="4">
                <CSelect id="provincial_id2"
                         label="Województwo"
                         v-model.trim="$v.form.provincial_id2.$model"
                         :options="provincials"
                >
                </CSelect>
              </CCol>
            </CRow>



            <h4 class="py-3">Adres fakturowy </h4>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Firma"
                  v-model.trim="$v.form.business3.$model"
                  :isValid="checkIfValid('business3')"
                  placeholder="Nazwa firmy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="NIP"
                  v-model.trim="$v.form.nip3.$model"
                  :isValid="checkIfValid('nip3')"
                  placeholder="Numer NIP"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Kod pocztowy"
                  v-model.trim="$v.form.postal_code3.$model"
                  :isValid="checkIfValid('postal_code3')"
                  placeholder="Kod pocztowy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Miasto"
                  v-model.trim="$v.form.city3.$model"
                  :isValid="checkIfValid('city3')"
                  placeholder="Miasto"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="4">
                <CInput
                  label="Ulica"
                  v-model.trim="$v.form.street3.$model"
                  :isValid="checkIfValid('street3')"
                  placeholder="Nazwa ulicy"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Numer"
                  v-model.trim="$v.form.number3.$model"
                  :isValid="checkIfValid('number3')"
                  placeholder="Numer budynku"
                  :invalidFeedback="$i18n.translate('forms.transl30')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="4">
                <CSelect id="country_id3"
                         label="Kraj"
                         v-model.trim="$v.form.country_id3.$model"
                         :options="countries"
                >
                </CSelect>
              </CCol>
              <CCol md="4">
                <CSelect id="provincial_id3"
                         label="Województwo"
                         v-model.trim="$v.form.provincial_id3.$model"
                         :options="provincials"
                >
                </CSelect>
              </CCol>
            </CRow>

            <CTextarea
              label="Notatka"
              placeholder="Notatka"
              rows="9"
              v-model.trim="$v.form.content.$model"
            />

            <!--            <CButton-->
            <!--              color="primary"-->
            <!--              :disabled="$v.form.$invalid"-->
            <!--              @click="submit"-->
            <!--            >-->
            <CButton
              color="primary"
              @click="submit"
            >
               {{ $i18n.translate('forms.transl52') }}
            </CButton>
            <CButton
  class="ml-1"
  color="success"
  @click="goBack"
>
  {{ $i18n.translate('forms.transl53') }}
</CButton>

          </CForm>
          <br />
        </CCol>
      </CRow>
    </CCardBody >
  </CCard >
</template >

<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"

export default {
  data() {
    return {
      form: {
        // name: null,
        // surname: null,
        contact_person: null,
        email1: null,
        email2: null,
        email3: null,
        content: null,
        nip1: null,
        regon1: null,
        business1: null,
        phone1a: null,
        phone1b: null,
        phone1c: null,
        street1: null,
        number1: null,
        postal_code1: null,
        city1: null,
        country_id1: null,
        provincial_id1: null,
        nip2: null,
        business2: null,
        phone2: null,
        street2: null,
        number2: null,
        postal_code2: null,
        city2: null,
        country_id2: null,
        provincial_id2: null,
        nip3: null,
        business3: null,
        phone3: null,
        street3: null,
        number3: null,
        postal_code3: null,
        city3: null,
        country_id3: null,
        provincial_id3: null,
        // countries: null,
        // provincials: null,
      },
      countries: [],
      provincials: [],
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      contact_person: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      nip1: {
        required,
        minLength: minLength(1),
        maxLength: 255
      },
      business1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      street1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      number1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      postal_code1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      city1: {
        required,
        minLength: minLength(3),
        maxLength: 255
      },
      country_id1: {
      },
      provincial_id1: {
      },
      email1: {
        maxLength: 255
      },
      email2: {
        maxLength: 255
      },
      email3: {
        maxLength: 255
      },
      content: {
        maxLength: 255
      },
      regon1: {
        maxLength: 255
      },
      phone1a: {
        maxLength: 255
      },
      phone1b: {
        maxLength: 255
      },
      phone1c: {
        maxLength: 255
      },
      nip2: {
        maxLength: 255
      },
      business2: {
        maxLength: 255
      },
      phone2: {
        maxLength: 255
      },
      street2: {
        maxLength: 255
      },
      number2: {
        maxLength: 255
      },
      postal_code2: {
        maxLength: 255
      },
      city2: {
        maxLength: 255
      },
      country_id2: {
      },
      provincial_id2: {
      },
      nip3: {
        maxLength: 255
      },
      business3: {
        maxLength: 255
      },
      phone3: {
        maxLength: 255
      },
      street3: {
        maxLength: 255
      },
      number3: {
        maxLength: 255
      },
      postal_code3: {
        maxLength: 255
      },
      city3: {
        maxLength: 255
      },
      country_id3: {
      },
      provincial_id3: {
      },
    }
  },
  methods: {
    goBack() {
      this.$router.replace({ path: '/clients' })
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    store() {
      let self = this;
      axios.post(this.$apiAdress + '/api/clients/' + self.$route.params.id + '?token=' + localStorage.getItem("api_token")+ '&s_lng=' + self.$i18n.locale(),
        {
          _method: 'PUT',
          contact_person: self.form.contact_person,
          email1: self.form.email1,
          email2: self.form.email2,
          email3: self.form.email3,
          content: self.form.content,
          nip1: self.form.nip1,
          regon1: self.form.regon1,
          business1: self.form.business1,
          phone1a: self.form.phone1a,
          phone1b: self.form.phone1b,
          phone1c: self.form.phone1c,
          street1: self.form.street1,
          number1: self.form.number1,
          postal_code1: self.form.postal_code1,
          city1: self.form.city1,
          country_id1: self.form.country_id1,
          provincial_id1: self.form.provincial_id1,
          nip2: self.form.nip2,
          business2: self.form.business2,
          phone2: self.form.phone2,
          street2: self.form.street2,
          number2: self.form.number2,
          postal_code2: self.form.postal_code2,
          city2: self.form.city2,
          country_id2: self.form.country_id2,
          provincial_id2: self.form.provincial_id2,
          nip3: self.form.nip3,
          business3: self.form.business3,
          phone3: self.form.phone3,
          street3: self.form.street3,
          number3: self.form.number3,
          postal_code3: self.form.postal_code3,
          city3: self.form.city3,
          country_id3: self.form.country_id3,
          provincial_id3: self.form.provincial_id3,
          countries: self.form.countries,
          provincials: self.form.provincials
        })
        .then(function (response) {
          if (response.data.status == 'success') {
            Swal.fire(
              self.$i18n.translate('forms.transl13'),
self.$i18n.translate('forms.transl62'),
              'success'
            ).then(function () {
              self.$router.push('/clients');
            });
          } else {
            Swal.fire(
              self.$i18n.translate('forms.transl64'),
              response,
              'error'
            )
          }
        }).catch(function (error) {
        if (error.response.data.message != '') {
          let errorDetails = ""
          for (let key in error.response.data.errors) {
            errorDetails += `${error.response.data.errors[key]}<br/>`
          }
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            errorDetails,
            'error'
          )
          window.scrollTo({top: 0});
        } else {
          Swal.fire(
            self.$i18n.translate('forms.transl64'),
            error,
            'error'
          )
          self.$router.push({path: 'login'});
        }
      });
    },

    submit() {
      this.validate();
      if(!this.$v.form.$invalid) {
        this.store();
      }
    },

    validate() {
      this.$v.$touch()
    },

    reset() {
      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },

    getEmptyForm() {
      return {
        // name: null,
        // surname: null,
        contact_person: null,
        email1: null,
        email2: null,
        email3: null,
        content: null,
        nip1: null,
        regon1: null,
        business1: null,
        phone1a: null,
        phone1b: null,
        phone1c: null,
        street1: null,
        number1: null,
        postal_code1: null,
        city1: null,
        country_id1: null,
        provincial_id1: null,
        nip2: null,
        business2: null,
        phone2: null,
        street2: null,
        number2: null,
        postal_code2: null,
        city2: null,
        country_id2: null,
        provincial_id2: null,
        nip3: null,
        business3: null,
        phone3: null,
        street3: null,
        number3: null,
        postal_code3: null,
        city3: null,
        country_id3: null,
        provincial_id3: null,
        // countries: null,
        // provincials: null,
      }
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/clients/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.provincials = response.data.provincials;
        self.countries = response.data.countries;

        // name: null,
        // surname: null,
        self.form.contact_person= response.data.record.contact_person;
        self.form.email1= response.data.record.email1;
        self.form.email2= response.data.record.email2;
        self.form.email3= response.data.record.email3;
        self.form.content= response.data.record.content;
        self.form.nip1= response.data.record.nip1;
        self.form.regon1= response.data.record.regon1;
        self.form.business1= response.data.record.business1;
        self.form.phone1a= response.data.record.phone1a;
        self.form.phone1b= response.data.record.phone1b;
        self.form.phone1c= response.data.record.phone1c;
        self.form.street1= response.data.record.street1;
        self.form.number1= response.data.record.number1;
        self.form.postal_code1= response.data.record.postal_code1;
        self.form.city1= response.data.record.city1;
        self.form.country_id1= response.data.record.country_id1;
        self.form.provincial_id1= response.data.record.provincial_id1;
        self.form.nip2= response.data.record.nip2;
        self.form.business2= response.data.record.business2;
        self.form.phone2= response.data.record.phone2;
        self.form.street2= response.data.record.street2;
        self.form.number2= response.data.record.number2;
        self.form.postal_code2= response.data.record.postal_code2;
        self.form.city2= response.data.record.city2;
        self.form.country_id2= response.data.record.country_id2;
        self.form.provincial_id2= response.data.record.provincial_id2;
        self.form.nip3= response.data.record.nip3;
        self.form.business3= response.data.record.business3;
        self.form.phone3= response.data.record.phone3;
        self.form.street3= response.data.record.street3;
        self.form.number3= response.data.record.number3;
        self.form.postal_code3= response.data.record.postal_code3;
        self.form.city3= response.data.record.city3;
        self.form.country_id3= response.data.record.country_id3;
        self.form.provincial_id3= response.data.record.provincial_id3;
        self.validate();
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });
  }
}
</script>
